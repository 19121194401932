<template>
    <div>
        <b-modal v-model="showModal" :title=" 'Product list' " content-class="card " header-class="card-header" cancel-variant="danger" size="md" footer-class="border-0" >
            <v-observer tag="form" ref="formPagination" class="m-md-0 mx-2"  @submit.prevent="fnResetProductsPagination()" >
                <div class="row">
                    <div class="col-12" >
                        <div class="form-group animated fadeIn ">
                            <label for="" >  <i class="fa fa-keyboard mr-1 "></i>  <span v-text=" $t('dashboard.products.name') " ></span> </label>
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Keyword" v-model="productsPagination.name " @change="fnResetProductsPagination()">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-primary" type="button"> <i class="fa fa-search"></i> </button>
                                </div>
                            </div>
                        </div>
                    </div> 

                    <div class="col-6">
                        <div class="form-group animated fadeIn ">
                            <label for="" > <i class=" fa fa-shopping-bag mr-1 "></i>  <span v-text=" $t('dashboard.products.store') " > </span> </label>
                            <select class=" form-control " v-model="productsPagination.storeId " @change="fnResetProductsPagination() "  >
                                <option :value="null" v-text="$t('general.form.all')"> </option>
                                <option :value="store.id" v-for=" (store,index) in storeList " :key="index" v-text=" store.name ? store.name : fnStoreURL(store.url) "> </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="form-group animated fadeIn ">
                            <label for="" > <i class="fa fa-circle mr-1 "></i> <span v-text=" $t('dashboard.products.status') " ></span> </label>
                            <select class=" form-control " v-model="productsPagination.active " @change="fnResetProductsPagination()" >
                                <option :value="null" v-text="$t('general.form.all')"> </option>
                                <option :value="true"> Online </option>
                                <option :value="false"> Offline </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-6 col-sm-6 col-lg-2 col-xl-1 d-none " >
                        <div class="form-group animated fadeIn ">
                            <label for="" > <i class="fa fa-arrows-v"></i> Limit </label>
                            <select class=" form-control " v-model="productsPagination.limit " @change="fnResetProductsPagination( );" >
                                <option :value="limit" v-for=" (limit,index) in CONST.PAGINATION " :key="index" v-text=" limit "> </option>
                            </select>
                        </div>
                    </div>
                </div>
            </v-observer>

            <div class=" table-scroll animated fadeIn " v-show=" productList.length > 0 " >
                <table class=" table table-hover table-sm table-striped ">
                    <thead class="">
                        <tr>
                            <th></th>
                            <th class="w-100">Product </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for=" (product,index) in productList " :key="index"  >
                            <td>
                                <input type="checkbox" @change="fnEmitProductList" class="mt-1" :value=" product.id " v-model="tempProductList" :id=' product.id ' > 
                            </td>
                            <td>
                                <label :for=' product.id ' class=" m-0 w-100" > 
                                    <div class="row">
                                        <div class="col "> 
                                            <p v-text=" product.name " > </p>
                                        </div>
                                        <div class="col-auto">
                                            <p  v-text=" fnFormatPrice( product.price , product.currency ) " >  </p>
                                        </div>
                                    </div>
                                </label>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <no-data :dataMessage="$t('noDataMessages.productList')" v-show=" productList.length == 0" :isLoading="isLoading" :showBtn="false"> </no-data>

            <b-pagination class="animated fadeIn " v-model="productsPagination.page" :total-rows="productsPagination.total" :per-page="productsPagination.limit" @input="fnApiGetProducts" align="fill"
                        :first-text="$t('general.button.first')"  :prev-text="$t('general.button.previous')" :next-text="$t('general.button.next')" :last-text="$t('general.button.last')" v-show="productsPagination.total > 0 && ( (productsPagination.total / productsPagination.limit) > 1 ) " ></b-pagination>
             <template v-slot:modal-footer>
                <div class="d-flex jusify-content-end ">
                    <button class="btn btn-primary " v-text=" 'Done'" @click=" showModal = false " > </button>
                </div>
            </template>

        </b-modal>
    </div>
</template>

<script>
export default {
    props:{
        showModalProducts:{
            type: Boolean,
            default: false,
        },
        products:{
            type: Array,
            required: true,
        }
    },
    watch: {
        products(){
            this.tempProductList = this.products;
        },
        showModalProducts(){
            this.showModal = true;
        },
    },
    
    data(){
        return( {
            showModal:false,
            isLoading:false,
            productsPagination:{
                    name: null,
                    ecommerce: null,
                    active: null,
                    page: 1,
                    limit: 5,
                    total: null,
                },
           
            storeList:[],
            productList:[],
            tempProductList:[],

        } )
    }, 
    methods:{ 
        async fnApiGetProducts(){
            this.isLoading = true;
            this.productList = [];
            let {name , storeId, active , limit , page } = this.$lodash.clone( this.productsPagination ); 
            let tempProductsPagination = { page,limit };
            if( name != null      && name != '' ){  tempProductsPagination.name = name };
            if( storeId != null && storeId != '' ){  tempProductsPagination.store_id = storeId };
            if( active !== null    && active !== ''){  tempProductsPagination.active = active };

            await axios.get( 'products', { params : tempProductsPagination } ).then(async (response) => {
                if( response.data && response.data.length > 0  ){
                        for( let index in response.data ){
                            response.data[index].show = false ;
                            response.data[index].selected = false ;
                            if( !response.data[index].category ){
                                response.data[index].category = {
                                    id:null,
                                }
                            }
                        }
                }
                this.productList = response.data;
            }).catch(error => {});
            this.isLoading = false;
        },

        fnApiGetStoreList(){
            axios.get( 'stores' ).then(async (response) => {
                if( response.data && response.data.length > 0 ){
                    this.storeList = response.data;
                }else{
                    this.isLoading = false;
                }
            }).catch(error => {});
        },

        fnApiGetCountProducts(){
            let {name , storeId, active } = this.$lodash.cloneDeep( this.productsPagination ); 
            let tempProductsPagination = {};
            if( name != null      && name != '' ){  tempProductsPagination.name = name };
            if( storeId != null && storeId != '' ){  tempProductsPagination.store_id = storeId };
            if( active !== null    && active !== ''){  tempProductsPagination.active = active };
            axios.get( 'products/count' , { params : tempProductsPagination } ).then(async (response) => {
                this.productsPagination.total = response.data.count ;
            }).catch(error => {
            });
        },

        fnResetProductsPagination(){
            this.productsPagination.page = 1;
            this.fnApiGetProducts( ); 
            this.fnApiGetCountProducts();
        },

        fnFirstProductsPagination(){
            this.productsPagination.page = 1;
            this.productsPagination.limit = this.CONST.PAGINATION[0];
            this.productsPagination.storeId = null;
            this.productsPagination.name = null;
            this.fnApiGetProducts( );
            this.fnApiGetCountProducts();
            this.fnApiGetStoreList();
        },
        fnEmitProductList(){
            this.$emit( 'update-list', this.$lodash.clone( this.tempProductList ) );
        }

    },
    mounted(){
        this.fnFirstProductsPagination();
    }
}
</script>

<style>

</style>